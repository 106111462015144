/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

@mixin beforeAfter {
  &:before,
  &:after {
    content: none;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

body {
  min-height: calc(100vh - 80px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //overflow-x: hidden;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -o-text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

#root {
  position: relative;
  // overflow: hidden;
}

/* HTML5 display-role reset for older browsers */

* {
  box-sizing: border-box;
  &:after,
  &:before {
    box-sizing: border-box;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
  @include beforeAfter;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

input {
  outline: none;
  -webkit-appearance: none;
}

button {
  cursor: pointer;
  outline: none;
}

// prevent to disaplying 3ds iframe
.secure3ds {
  height: 0 !important;
  width: 0 !important;
}

.secure3ds_challange {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  border-style: solid;
  border-color: rgba(#000000, 0.8);
  border-width: 25px;
  visibility: visible;

  @media screen and (min-width: 768px) {
    border-width: 70px;
  }

  @media screen and (min-width: 1200px) {
    border-width: 120px;
  }
}
